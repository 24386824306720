import { z } from "zod";

export const GiftDetailsSchema = z.object({
  comment: z.object({
    txt: z.object({
      recipient: z
        .string()
        .min(1, "Recipient name is required")
        .max(100, "Recipient name too long"),
      message: z.string().max(150, "Message too long").optional(),
    }),
  }),
});

export type GiftDetails = z.infer<typeof GiftDetailsSchema>["comment"]["txt"];

export const ItemSchema = z.object({
  id: z.string().min(1, "Item ID is required"),
  giftDetails: GiftDetailsSchema,
});

export const ResponseSchema = z.object({
  success: z.boolean(),
  message: z.string(),
  data: z.any().optional(),
});

export const addGiftWrappingInput = z.object({
  item: ItemSchema,
  token: z.string().min(1, "Token is required"),
});

export type AddGiftWrappingInput = z.infer<typeof addGiftWrappingInput>;
export type AddGiftWrappingApiResponse = z.infer<typeof ResponseSchema>;
