import type { MultilinkStoryblok } from "@/types/storyblok-blok-types";
import { globalLinkMap } from "./linkMap";

const isProductionVercel = process.env.VERCEL_ENV === "production";
const isServer = typeof window === "undefined";

const formatUrlLink = (link: MultilinkStoryblok) => {
  if ("url" in link) return link.url;
  throw new Error("Link is not an asset or url link");
};

const checkIfInfoPage = (link: string) => {
  const infoPagesRegex = /\/info-pages(\/.+)$/i;
  const isInfoPage = link.match(infoPagesRegex);
  const url = isInfoPage && isInfoPage[1] ? `/${isInfoPage[1]}` : null;

  return url;
};

const checkIfHome = (link: string) => {
  const homeRegex = /^\/?home(?![a-zA-Z])/i;
  const isHome = link.match(homeRegex);
  const url = isHome && isHome[0] ? "/" : null;

  return url;
};
const getStoryUrl = (link: MultilinkStoryblok) => {
  if ("story" in link) return link.story?.full_slug || "";

  // if(link.linktype === "story" && link.story.)
  if (link.linktype === "story") {
    const linkFromGlobal = globalLinkMap.get(link.id!);
    if (linkFromGlobal) {
      if (
        linkFromGlobal !== link.cached_url &&
        !isProductionVercel &&
        isServer
      ) {
        // console.log(
        //   `Link ${link.id} has a different cached url: [CACHED:${link.cached_url}] vs [FRESH:${linkFromGlobal}]`
        // );
      }
      return linkFromGlobal;
    }
    return link.cached_url || "";
  }
  throw new Error("Link is not a story link");
};

const formatStoryLink = (link: MultilinkStoryblok) => {
  const url = getStoryUrl(link);
  if (checkIfInfoPage(url)) return checkIfInfoPage(url) as string;
  if (checkIfHome(url)) return checkIfHome(url) as string;
  return url.startsWith("/") ? url : `/${url}`;
};

type EmailLink = {
  linktype: "email";
  email: string;
};

const isEmail = (link?: MultilinkStoryblok): link is EmailLink => {
  return (link as EmailLink)?.linktype === "email";
};

const getLink = (link: MultilinkStoryblok | undefined) => {
  if (link?.linktype === "url" || link?.linktype === "asset") {
    return formatUrlLink(link) ?? "";
  }
  if (link?.linktype === "story") {
    return formatStoryLink(link) ?? "";
  }

  if (isEmail(link)) {
    return link?.email ? `mailto:${link?.email}` : "";
  }

  return "";
};

function isVarsityUrl(url: string) {
  const regex = /^https?:\/\/(www\.)?varsityheadwear\.com/;
  return regex.test(url);
}

export const removeDeadSBSlugs = (url: string) => {
  if (
    (url.startsWith("https://") || url.startsWith("http://")) &&
    !isVarsityUrl(url)
  )
    return url;

  url = url
    .replace("/pages", "")
    .replace("/en", "/")
    .replaceAll("//", "/")
    .replace(":/", "://");

  url = url.replaceAll("//", "/");
  return url;
};

export default getLink;
