"use client";

import clsx from "clsx";
import type { PropsWithChildren } from "react";
import { useState } from "react";

import styles from "./input.module.css";

/**
 * A reusable input component that can be used throughout the application.
 *
 * @param value - The value of the input.
 * @param setValue - A function that sets the value of the input.
 * @param inputProps - Additional props to pass to the input element.
 * @param labelProps - Additional props to pass to the label element.
 */

type Props = PropsWithChildren<{
  value?: string;
  setValue?: (value: string) => void;
  inputProps?: Omit<React.InputHTMLAttributes<HTMLInputElement>, "children">;
  labelProps?: Omit<React.LabelHTMLAttributes<HTMLLabelElement>, "children">;
}>;

const Input = (props: Props) => {
  const [localValue, setLocalValue] = useState("");
  return (
    <label
      className={clsx(
        styles.label,
        (localValue.length > 0 || (props?.value && props.value.length > 0)) &&
          styles.active,
        props.labelProps?.className,
      )}
      {...props.labelProps}
    >
      <input
        type={props.inputProps?.type ?? "text"}
        className={clsx(styles.input, localValue && styles.active)}
        value={props?.value ?? localValue}
        onChange={
          props?.setValue
            ? (e) => props?.setValue?.(e.target.value)
            : (e) => setLocalValue(e.target.value)
        }
        {...props.inputProps}
      />
      {props.children}
    </label>
  );
};

export default Input;
