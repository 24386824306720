"use client";

import Plus from "@/icons/Plus";
import * as Acc from "@radix-ui/react-accordion";
import clsx from "clsx";
import { forwardRef, useImperativeHandle, useRef } from "react";

import Minus from "@/icons/Minus";
import styles from "./accordion.module.css";

/**
 * Accordion component that uses Radix UI's Accordion components.
 * @param title - The title of the accordion.
 * @param children - The content of the accordion.
 * @param styleProps - An optional object containing CSS classes for the accordion, trigger, and content.
 * @param ref - An optional ref object that can be used to invoke the exposed trigger method.
 * @param icon - An optional icon to display next to the title.
 * @returns A React component representing an accordion.
 */

// Define the ref methods type
export interface AccordionRef {
  trigger: () => void;
  open: () => void;
  close: () => void;
}

interface AccordionProps {
  title: string;
  descriptor?: string;
  children: React.ReactNode;
  styleProps?: {
    accordion?: string;
    trigger?: string;
    content?: string;
    color?: "white";
  };
}

const Accordion = forwardRef<Partial<AccordionRef>, AccordionProps>(
  function Accordion({ title, descriptor, children, styleProps }, ref) {
    const triggerRef = useRef<HTMLButtonElement>(null);

    useImperativeHandle(ref, () => ({
      trigger: () => {
        triggerRef.current?.click();
      },
      open: () => {
        const currentState = triggerRef.current?.dataset.state as
          | "open"
          | "closed"
          | undefined;
        if (currentState !== "open") {
          triggerRef.current?.click();
        }
      },
      close: () => {
        const currentState = triggerRef.current?.dataset.state as
          | "open"
          | "closed"
          | undefined;
        if (currentState === "open") {
          triggerRef.current?.click();
        }
      },
    }));

    const color = styleProps?.color;

    const accordionClasses = clsx(
      styles.accordion,
      color ? styles[color] : null,
      styleProps?.accordion,
    );
    const triggerClasses = clsx(
      styles.trigger,
      color ? styles[color] : null,
      styleProps?.trigger,
    );
    const contentClasses = clsx(
      styles.content,
      color ? styles[color] : null,
      styleProps?.content,
    );

    return (
      <Acc.Item value={title} className={accordionClasses}>
        <Acc.AccordionTrigger className={triggerClasses} ref={triggerRef}>
          {title}
          <div className={styles.decorator}>
            {descriptor && (
              <span className={styles.descriptor}>{descriptor}</span>
            )}
            <Plus />
            <Minus />
          </div>
        </Acc.AccordionTrigger>

        <Acc.AccordionContent className={contentClasses}>
          {children}
        </Acc.AccordionContent>
      </Acc.Item>
    );
  },
);

export default Accordion;
