"use client";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import Cookies from "js-cookie";
import { useRouter } from "next/navigation";

import { PATHS } from "@/consts";
import { logoutUser } from "@/lib/centra/selectionFunctions";
import usePersistedStore from "@/lib/stateManagement/persistedState/persistedStore";

export default function useLogOut() {
  const queryClient = useQueryClient();
  const customerToken = usePersistedStore((state) => state.token);
  const router = useRouter();

  const logOut = useMutation({
    mutationFn: () => logoutUser(customerToken),

    onError: async (error: Error) => {
      if (window.CentraCheckout) window.CentraCheckout.resume();

      return error;
    },

    onSuccess: async (data) => {
      usePersistedStore.setState({ token: "" });
      queryClient.invalidateQueries({
        queryKey: ["selection"],
      });
    },

    // Even on unsuccessful logout, we want to remove the token and redirect to login
    onSettled: () => {
      Cookies.remove("token");
      router.push(PATHS.login);
      router.refresh();
    },
  });

  return { logOut };
}
