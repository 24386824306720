import type { SbBlokData } from "@storyblok/react/rsc";
import { storyblokEditable } from "@storyblok/react/rsc";
import clsx from "clsx";

import { Heading, Subtitle } from "@/components/atoms";
import type {
  ButtonAtomStoryblok,
  HeroStoryblok,
  RichtextStoryblok,
} from "@/types/storyblok-blok-types";

import BlokWrap from "@/components/atoms/BlokWrap";
import RichText from "@/components/atoms/RichText/RichText";
import StoryblokComponent from "@/lib/bloks/StoryblokComponent/StoryblokComponent";
import styles from "./hero.module.css";

const textHasButton = (field?: RichtextStoryblok) => {
  try {
    return field
      ? !!field.content?.find(
          (blok) =>
            blok.type === "blok" &&
            blok.attrs?.body.find((b: ButtonAtomStoryblok) => {
              return b?.component === "buttonAtom";
            }),
        )
      : false;
  } catch (error) {
    return false;
  }
};

export const HeroUI = ({
  blok,
  length,
  blokIndex,
}: {
  blok: HeroStoryblok & {
    backgroundOpacity?: any;
  };
  length: number;
  blokIndex?: number;
}) => {
  const {
    media,
    preamble,
    title,
    button,
    textRTF,
    overlayText,
    backgroundOpacity,
    textPositionDesktop,
    textPositionMobile,
  } = blok;
  const buttonBlok = button ? button[0] : false;

  const buttonInRTF = textHasButton(textRTF);

  return (
    <BlokWrap
      animated="custom"
      data-layout={!media?.length ? "page" : "full"}
      {...storyblokEditable(blok as unknown as SbBlokData)}
      className={clsx(
        styles.root,
        styles.outerwrapper && styles.inset,
        overlayText && styles.overlay,
        !media?.length && styles.no_image,
        blok.displaySideBySide && styles.sideBySide,
        "hero",
      )}
    >
      <div className={styles.wrapper__image}>
        <div
          className={styles.wrapper__image_overlay}
          style={{ opacity: (backgroundOpacity?.value ?? 0) / 100 }}
        />
        {media && media[0] && <StoryblokComponent eager blok={media[0]} />}
      </div>

      <div className={`${styles.wrapper} hero-content`}>
        <div
          className={clsx(
            textPositionMobile && styles[textPositionMobile],
            textPositionDesktop && styles[textPositionDesktop],
          )}
        >
          {preamble && preamble?.length > 0 && (
            <Subtitle className={styles.text}>{preamble}</Subtitle>
          )}
          {title && (
            <div className="hero-title">
              <Heading
                as={blokIndex === 1 ? "h1" : "h2"}
                styledAs={length === 2 ? "h2" : "h1"}
                className={styles.heading}
              >
                {title}
              </Heading>
            </div>
          )}
          {!!textRTF && (
            <RichText className={styles.richText}>{textRTF}</RichText>
          )}
          {buttonBlok && !buttonInRTF && (
            <StoryblokComponent blok={buttonBlok} />
          )}
        </div>
      </div>
    </BlokWrap>
  );
};
