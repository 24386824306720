import { ICartItem } from "@/lib/centra/formatters/types";
import { useTranslations } from "next-intl";
import styles from "./gift-wrapping.module.css";

type GiftWrappingSummaryProps = {
  item: ICartItem;
};

export function GiftWrappingSummary({ item }: GiftWrappingSummaryProps) {
  const t = useTranslations();

  if (!item.giftWrapping) {
    return null;
  }

  const { recipient, message } = item.giftWrapping;
  return (
    <div className={styles.giftDetails}>
      <p>{`${t("checkout.orderInfo.sections.giftWrapping.form.to.label")}: ${recipient}`}</p>
      {message && (
        <p>{`${t("checkout.orderInfo.sections.giftWrapping.form.message.label")}: ${message}`}</p>
      )}
    </div>
  );
}
