"use client";
import type { SbBlokData } from "@storyblok/react/rsc";
import { storyblokEditable } from "@storyblok/react/rsc";
import clsx from "clsx";
import Image from "next/image";

import BlokWrap from "@/components/atoms/BlokWrap";
import FormatStoryblokMedia from "@/lib/storyblok/utils/Media/FormatStoryblokMedia";
import type { MediaStoryblok } from "@/types/storyblok-blok-types";

import { Video } from "@/components/atoms";
import styles from "./media.module.css";

const checkIsFull = (blok: MediaStoryblok) => {
  return blok.alignment === "full";
};

const FixedImageElement = ({ blok }: { blok: MediaStoryblok }) => {
  const full = checkIsFull(blok);

  const { desktop: desktopMedia, mobile: mobileMedia } = FormatStoryblokMedia(
    blok?.media?.[0],
  );

  const sizes = {
    desktop: {
      width: parseInt(desktopMedia?.width ?? "1920"),
      height: parseInt(desktopMedia?.height ?? "1920"),
    },
    mobile: {
      width: parseInt(mobileMedia?.width ?? "1000"),
      height: parseInt(mobileMedia?.height ?? "1000"),
    },
  };

  const hasDesktopImage =
    !!desktopMedia?.file && desktopMedia.isImage && !desktopMedia.isVideo;
  const hasMobileImage =
    !!mobileMedia?.file && mobileMedia.isImage && !mobileMedia.isVideo;

  return (
    <>
      {hasDesktopImage && (
        <Image
          src={desktopMedia.file}
          alt={desktopMedia.alt}
          data-no-mobile={!hasMobileImage}
          width={full ? 1920 : sizes.desktop.width}
          height={full ? 1920 : sizes.desktop.height}
          sizes={full ? "100vw" : undefined}
          className={clsx(styles.desktop, full && styles.full)}
          data-full={full}
        />
      )}

      {hasMobileImage && (
        <Image
          src={mobileMedia.file}
          alt={mobileMedia.alt}
          data-no-mobile={!hasMobileImage}
          width={full ? 1920 : sizes.mobile.width}
          height={full ? 1920 : sizes.mobile.height}
          sizes={full ? "100vw" : undefined}
          className={styles.mobile}
        />
      )}
    </>
  );
};

export const VideoMediaElement = ({ filename }: { filename?: string }) => {
  if (!filename) return <></>;

  return (
    <Video
      background={true}
      url={filename}
      autoPlay={true}
      loop={false}
      muted={false}
      controls={true}
      width="100%"
      height="100%"
      style={{
        position: "absolute",
        top: 0,
        left: 0,
      }}
    />
  );
};

export const VideoElement = ({ blok }: { blok: MediaStoryblok }) => {
  const full = checkIsFull(blok);

  return (
    <>
      <div className={clsx(styles.desktop, styles.video)} data-full={full}>
        <VideoMediaElement filename={blok.media?.[0]?.desktopMedia.filename} />
      </div>

      <div className={clsx(styles.mobile, styles.video)} data-full={full}>
        <VideoMediaElement filename={blok.media?.[0]?.mobileMedia?.filename} />
      </div>
    </>
  );
};

const MediaContent = ({
  blok,
  children,
}: {
  children: React.ReactElement;
  blok: MediaStoryblok;
}) => {
  const full = checkIsFull(blok);

  return (
    <BlokWrap
      {...storyblokEditable(blok as unknown as SbBlokData)}
      className={clsx(
        styles.base,
        full && styles.full,
        blok.alignment === "center" && styles.center,
        blok.alignment === "left" && styles.left,
        blok.alignment === "right" && styles.right,
        "media-blok",
      )}
      data-blok
      data-blok-background
      data-full={full}
      data-alignment={blok.alignment}
      data-layout={full ? "full" : "page"}
      animated={full ? "custom" : "fadeY"}
    >
      <>{children}</>
    </BlokWrap>
  );
};

const Media = ({ blok }: { blok: MediaStoryblok }) => {
  const mediaExtension =
    blok.media?.[0]?.desktopMedia.filename.split(".").pop() || "";

  const isVideoFile = ["mp4", "webm", "ogg"].includes(mediaExtension);

  if (isVideoFile) {
    return (
      <MediaContent blok={blok}>
        <VideoElement blok={blok} />
      </MediaContent>
    );
  }

  if (blok.component === "media") {
    return (
      <MediaContent blok={blok}>
        <FixedImageElement blok={blok} />
      </MediaContent>
    );
  }

  return <></>;
};

export default Media;
