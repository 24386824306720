"use client";

import { serverFetchProductsByIds } from "@/app/serverFunctionsCentra";
import { ProductCard } from "@/components/atoms";
import SkeletonPC from "@/components/atoms/ProductCard/SkeletonPC";
import { CentraProductServer } from "@/lib/centra/atomicSetup";
import formatProductCard from "@/lib/centra/formatters/formatProductCards/formatProductCard";
import useDevice from "@/lib/hooks/useDevice";
import { useQuery } from "@tanstack/react-query";
import { useMemo } from "react";
import { StoryblokStory } from "storyblok-generate-ts";
import ProductGrid from "../ProductGrid";
import styles from "./index.module.css";

const RecommendedProductCardsSkeleton = () => {
  return (
    <ProductGrid className={styles.productGrid}>
      <SkeletonPC />
      <SkeletonPC />
      <SkeletonPC />
      <SkeletonPC />
      <SkeletonPC />
    </ProductGrid>
  );
};

const RecommendedCardsUI = ({ blok }: { blok: StoryblokStory<any> }) => {
  const { mobile } = useDevice();
  const { products: productIds } = blok?.content?.body[0];

  const { data, isLoading } = useQuery({
    queryKey: ["recommended-products", productIds],
    queryFn: async () => {
      const cards = await serverFetchProductsByIds(productIds);
      if (!cards) return [];
      return cards;
    },
  });

  const images = useMemo(
    () => (mobile ? data : data?.slice(0, 5)),
    [data, mobile],
  );

  if (isLoading)
    return (
      <>
        <RecommendedProductCardsSkeleton />
      </>
    );
  return (
    <ProductGrid className={styles.productGrid}>
      {images?.map((product: CentraProductServer, i: number) => {
        return (
          <ProductCard
            product={formatProductCard(product)}
            key={`product-${product?.product}`}
            priority={i < 5}
          />
        );
      })}
    </ProductGrid>
  );
};

export const RecommendedProductCards = ({
  blok,
}: {
  blok: StoryblokStory<any>;
}) => {
  if (!blok?.content?.body?.[0]) {
    return null;
  }
  return <RecommendedCardsUI blok={blok} />;
};
