import BlokWrap from "@/components/atoms/BlokWrap";
import RichText from "@/components/atoms/RichText/RichText";
import ChevronRight from "@/icons/ChevronRight";
import StoryblokComponent from "@/lib/bloks/StoryblokComponent/StoryblokComponent";
import getLink from "@/lib/storyblok/utils/getLink";
import { PlpBannerStoryblok } from "@/types/storyblok-blok-types";
import { SbBlokData, storyblokEditable } from "@storyblok/js";
import clsx from "clsx";
import Link from "next/link";
import styles from "./index.module.css";

const isValidLink = (link: PlpBannerStoryblok["link"]) => {
  const newLink = link || {};
  return (
    link?.cached_url ||
    ("url" in newLink && newLink?.url) ||
    ("id" in newLink && newLink.id)
  );
};

const CompWrapper = ({
  children,
  link,
}: {
  children: any;
  link: PlpBannerStoryblok["link"];
}) => {
  if (isValidLink(link)) {
    const href = getLink(link);
    return (
      <Link href={href} className={styles.contentContainer}>
        {children}
      </Link>
    );
  }
  return <div className={styles.contentContainer}>{children}</div>;
};

export const PlpBannerCard = ({ blok }: { blok: PlpBannerStoryblok }) => {
  const { media, title, text, link, linkText, backgroundColor } = blok;
  return (
    <BlokWrap {...storyblokEditable(blok as unknown as SbBlokData)}>
      <CompWrapper link={link}>
        {media && <StoryblokComponent priority={true} blok={media[0]} />}

        <div
          className={clsx(styles.textWrapper, styles?.[`${backgroundColor}`])}
        >
          {title && (
            <div className={clsx(styles.content)}>
              <h2 className={clsx(styles.title)}>{title}</h2>
            </div>
          )}

          <div className={styles.linkWrapper}>
            {!!text?.content?.[0]?.content?.[0]?.text &&
              text?.content?.[0]?.content?.[0]?.text?.length > 0 && (
                <RichText className={styles.text}>{text}</RichText>
              )}
            {linkText && (
              <p className={styles.linkText}>
                {linkText}
                <ChevronRight />
              </p>
            )}
          </div>
        </div>
      </CompWrapper>
    </BlokWrap>
  );
};
