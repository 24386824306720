"use client";
import ReactPlayer from "react-player/lazy";

import useHasHydrated from "@/lib/hooks/useHasHydrated";

import { useRef } from "react";
import Skeleton from "../Skeleton";

interface IVideoProps {
  url?: string;
  controls?: boolean;
  autoPlay?: boolean;
  muted?: boolean;
  loop?: boolean;
  [key: string]: unknown;
}

const Video = (props: IVideoProps) => {
  const containerRef = useRef(null);
  const hasHydrated = useHasHydrated();

  if (!props.url || !hasHydrated) return <Skeleton height="100%" />;

  const {
    controls = false,
    autoPlay = true,
    muted = true,
    loop = true,
    background = true,
  } = props;
  if (background) {
    return (
      <div style={{ width: "100%", height: "100%" }} ref={containerRef}>
        <ReactPlayer
          {...props}
          playsinline
          autoPlay
          muted
          playing
          className="react-player"
          loop
          controls={controls}
          config={{
            vimeo: {
              playerOptions: {
                responsive: true,
                playsinline: true,
                background: true,
                autoPlay: autoPlay,
                loop,
                muted,
              },
            },
          }}
          fallback={<Skeleton height="100%" />}
        />
      </div>
    );
  }
  return (
    <div style={{ width: "100%", height: "100%" }} ref={containerRef}>
      <ReactPlayer
        {...props}
        controls={controls}
        className="react-player"
        fallback={<Skeleton height="100%" />}
      />
    </div>
  );
};

export default Video;
