import env from "./env.mjs";

export const PATHS = {
  home: "/",
  account: "/account",
  login: "/login",
  register: "/register",
  forgotPassword: "/forgot-password",
  resetPassword: "/reset-password",
  setNewPassword: "/set-new-password",
  products: "/caps",
  product: "/product",
  checkout: {
    index: "/checkout",
    summary: "/checkout/summary",
    giftCards: "/gift-cards",
  },
  termsAndConditions: "/terms-and-conditions",
  privacyPolicy: "/privacy-policy",
} as const;

export const VIP_MARKET = env.NEXT_PUBLIC_CENTRA_ALL_PRODUCTS_MARKET;
export const AUTH_REQUIRED_PATHS = [PATHS.account] as const;
export const PUBLIC_ACCOUNT_PATHS = [
  PATHS.login,
  PATHS.register,
  PATHS.forgotPassword,
  PATHS.resetPassword,
  PATHS.setNewPassword,
] as const;
